@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"



















.category-list-skeleton
  display: flex
  flex-direction: column
  padding: 10px 64px 0

  &__body
    cursor: pointer
    margin: 20px 10px
    font-size: 20px

  &__bone
    height: 30px !important

