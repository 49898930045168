@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"



































































































































.categories-list
  .wrapper
      min-height: 80vh
      background: $color-ice

      +mq-s
        margin: 0
        max-width: 100%

  .categories-list-body
    display: flex
    flex-direction: column
    padding: 10px 64px 0

    +mq-s
      padding: 10px 24px 12px

    &__box
      cursor: pointer
      margin: 10px 10px
      color: $color-primary
      font-size: 20px
      font-weight: 600

    &__title
      display: flex
      padding: 50px 64px 0
      font-size: 20px
      margin-left: 10px

      +mq-s
        padding: 40px 24px 12px

      &__text
        font-size: 20px
        color: $color-main
        margin-left: 5px
